.img-resp {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.small-text {
  font-size: small;
}

.large-text {
  font-size: large;
}

a {
  color: inherit;
}

.upcase {
  text-transform: uppercase;
}

a:hover {
  text-decoration: none;
  color: initial;
}

.center_content {
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  color: #000;
  line-height: 1.3;
}

.center-link {
  display: block;
  text-align: center;
}

.responsive_img {
  width: 100%;
  height: auto;
}

.border-bck {
  border: 50px solid rgba(255, 255, 255, 0.2);
}

.responsive_img1 {
  width: auto;
  height: 48vh;
  float: left;
}

.logo {
  width: calc(9 * (1vw + 1vh - 1vmin));
  height: auto;
}

.mb-65 {
  margin-bottom: 6rem !important;
}

.rond-menu {
  width: 3vh;
}
.button-vert {
  background-color: #4cd0d0;
  height: 32px;
  border-radius: 50px;
  border: none;
}

a {
  text-decoration: none;
}

.button-vert:hover {
  -webkit-box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.4);
  transform: scale(1.1);
  color: #fff;
}

.titres-sitesB-home-mobile {
  font-size: 25px;
  font-family: "PlutoCondMedium";
  color: #fff;
  text-transform: uppercase;
}
@media (max-width: 375px) {
  .titres-sitesB-home-mobile {
    font-size: 22px;
  }
}
.text-buttons {
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: #fff;
}
.titres-sitesB-home {
  font-size: 36px;
  font-family: "PlutoCondMedium";
  color: #fff;
  text-transform: uppercase;
  word-spacing:  1px;
}
@media (max-width: 1050px) {
  .titres-sitesB-home {
    font-size: 30px;
  }
}
@media (max-width: 890px) {
  .titres-sitesB-home {
    font-size: 24px;
  }
}
@media (max-width: 1440px) {
  .text-buttons {
    font-size: 14px;
  }
}
@media (max-width: 1200px) {
  .text-buttons {
    font-size: 12px;
  }
  .radio input[type="radio"]:checked + .radio-label {
    font-size: 12px;
  }
}
@media (max-width: 1100px) {
  .text-buttons {
    font-size: 10px;
  }
  .titre-form {
    font-size: 14px;
  }
}
.line {
  line-height: 1.2;
}

.carousel-indicators li {
  width: 0.9vmin;
  height: 0.9vmin;
  border-radius: 100%;
  margin: 12px;
  opacity: 1;
  box-shadow: 3px 2px 7px 0px rgba(0, 0, 0, 0.6);
  border-top: 0;
  border-bottom: 0;
}
.carousel-control-next,
.carousel-control-prev {
  z-index: 15;
  opacity: 1;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  height: calc(1 * (1vw + 1vh - 1vmin));
  width: calc(1 * (1vw + 1vh - 1vmin));
}

.padding-content-center {
  padding-left: 15vmax !important;
  padding-right: 15vmax !important;
}

.carousel-caption {
  right: 0;
  left: 0;
  text-align: left;
  bottom: 0px;
}
.block-header {
  position: relative;
  z-index: 10;
  top: -24vmin;
}
.carousel-title {
  font-size: 2.9vw;
  font-family: "Bree Serif", serif;
  color: #fff;
}

.form-info {
  font-size: 0.9vw;
  font-family: "PlutoRegular";
  color: #fff;
}

.adroit {
  float: right;
}

.agauche {
  float: left;
}

.feuille {
  width: calc(3 * (1vw + 1vh - 1vmin));
  margin-left: -1rem !important;
}

.back-bleu {
  background-color: rgba(35, 47, 65, 0.7);
}

.back-gris {
  background-color: rgba(52, 55, 59, 0.527);
}

.coclicos {
  width: calc(8 * (1vw + 1vh - 1vmin));
}

.mt-7 {
  margin-top: 8rem !important;
}

.header-margin {
  margin-top: 7vmax !important;
}

.mt-6 {
  margin-top: 6rem !important;
}
.mt7 {
  margin-top: -5rem !important;
}

.icon-campus {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.titre-icons {
  font-size: 1.27vw;
  font-family: "PlutoRegular";
  color: #282828;
}

/* 9 line ... */
.texte-suite-9 {
  overflow: auto;
  height: 14vmax;
}
/* .texte-suite-9::-webkit-scrollbar { width: 0 !important }
.texte-suite-9 { overflow: -moz-scrollbars-none; }
.texte-suite-9 { -ms-overflow-style: none; } */
.texte-suite-9::-webkit-scrollbar {
  width: 5px;
}

.texte-suite-9::-webkit-scrollbar-thumb {
  background: #666;
}

.ourHr {
  background-color: #afb0b1;
  height: 1px;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  margin-left: auto;
  margin-right: auto;
}

.card {
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.card-body p {
  margin-bottom: 0.5rem;
}

.mb-6 {
  margin-bottom: 4rem !important;
}
.mb-7 {
  margin-bottom: 10rem !important;
}
/* mobile */

.logo-mobile {
  width: 30vmin;
  height: auto;
}

#carouselHeader-mobile .card-img-top {
  height: 65vh;
}

.back-bleu-mobile {
  background-color: rgba(28, 26, 36, 0.5);
}

@media (max-width: 768px) {
  #carouselHeader-mobile .d-md-block {
    display: block !important;
  }
}

.formation-mobile {
  font-size: 8vw;
  font-family: "Bree Serif", serif;
  color: #fff;
}

.button-vert-mobile {
  background-color: #4cd0d0;
  height: 36px;
  border-radius: 50px;
  border: none;
}
.button-vert-mobile a {
  text-decoration: none;
}

.form-info-mobile {
  font-size: 3vw;
  font-family: "PlutoRegular";
  color: #fff;
  letter-spacing: 0.2vw;
}

.arbres-mobile {
  width: 100%;
}

.text-buttons-mobile {
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #fff;
}

.flex-1 {
  flex: 1;
}

.flex-13 {
  flex: 13;
}
.home-header-size {
  height: 100vh;
}
.home-header-size-mobile {
  height: 100vh;
}
.home-arbres-size {
  height: calc(30 * (1vw + 1vh - 1vmin));
}
.home-arbres-size-mobile {
  height: calc(35 * (1vw + 1vh - 1vmin));
}

.arbres {
  width: auto;
  height: 28vw;
  float: right;
}

/* responsives */

.display-min {
  display: none;
}

@media (max-width: 768px) {
  .display-max {
    display: none;
  }

  .display-min {
    display: flex;
  }

  .display-block {
    display: block;
  }
  .titres-sites {
    font-size: 6vw;
    line-height: 1;
  }

  .b-border::before {
    width: 35%;
  }

  .carousel-indicators li {
    width: 2vmin;
    height: 2vmin;
    margin-bottom: -1vh;
  }
}
.block-color {
  background-color: rgba(42, 57, 109, 0.8);
  /* -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px); */
}

.block-ext {
  -webkit-box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.4);
}

.choisir-log {
  height: calc(13 * (1vw + 1vh - 1vmin));
}
.choisir-log-mobile {
  height: calc(35 * (1vw + 1vh - 1vmin));
}

.sous-titres {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #2a396d;
  text-transform: uppercase;
}

/* @media (max-width: 1440px) {
  .sous-titres {
    font-size: 14px;
  }
} */
.sous-titres-mobile {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #2a396d;
  text-transform: uppercase;
}

.titres2 {
  font-size: 36px;
  font-family: "PlutoMedium";
  color: #2a396d;
  line-height: 1;
}
.titres2-mobile {
  font-size: 24px;
  font-family: "PlutoMedium";
  color: #2a396d;
}

.texts-sites {
  font-size: 28px;
  font-family: "Montserrat", sans-serif;
  color: #929396;
  font-weight: 600;
}

.text-infoA {
  font-size: 21px;
  font-family: "PlutoMedium";
  color: #fff;
}
.text-infoA-mobile {
  font-size: 17px;
  font-family: "PlutoMedium";
  color: #fff;
}
.text-prix {
  font-size: 18px;
  font-family: "PlutoCondRegular";
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
}
.text-prix-mobile {
  font-size: 13px;
  font-family: "PlutoCondRegular";
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
}

.form-control {
  font-size: 14px;
  font-family: "PlutoCondMedium";
  border: 2px solid #fff;
  border-radius: 2rem;
  color: #fff;
  background-color: rgba(42, 57, 109, 0.8);
  height: 32px;
}

.form-control2 {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  height: 36px;
  padding: 0.375rem 0.75rem;
  color: #929396;
  font-weight: 900;
  background-color: #f8f8f8;
  border: 2px solid #929396;
  border-radius: 2rem;
}

.form-control2-mobilr {
  display: block;
  width: 100%;
  height: calc(2em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  font-family: "Montserrat", sans-serif;
  color: #929396;
  font-weight: 900;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 2px solid #929396;
  border-radius: 50px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (max-width: 1440px) {
 /* .titres2 {
    font-size: 30px;
  }
  .texts-sites {
    font-size: 20px;
  }
  .form-control2 {
    font-size: 14px;
  }
   .text-infoA {
    font-size: 20px;
  }
  .text-prix {
    font-size: 14px;
  } */
}
@media (max-width: 1024px) {
  /*.titres2 {
    font-size: 28px;
  }
  .texts-sites {
    font-size: 18px;
  }
  .form-control2 {
    font-size: 12px;
  }
   .text-infoA {
    font-size: 18px;
  }
  .text-prix {
    font-size: 13px;
  } */
}
.titres-sites {
  font-size: 2vw;
  font-family: "PlutoCondMedium";
  color: #354765;
  text-transform: uppercase;
}

.titres-sites-mobile {
  font-size: 6vw;
  font-family: "PlutoCondMedium";
  color: #354765;
  text-transform: uppercase;
}

.texts-sites2 {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 400;
  line-height: 1.2;
}
.texts-sites2-mobile {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 400;
  line-height: 1.2;
}

.texts-sites-mobile {
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  color: #929396;
  font-weight: 600;
}
.titres-sitesB-mobile {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 400;
}

.titres-sitesB {
  font-size: 48px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 400;
}

.titres-sitesB2-mobile {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #fff;
}

.titres-sitesB2 {
  font-size: 48px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #fff;
}
.border-right {
  border-right: 1px solid #fff !important;
}

.text-radio {
  font-size: 1vw;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}

.titre-form {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}

.titre-form-mobile {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  font-size: 14px;
  padding-left: 28px;
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 600;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 15px;
  height: 15px;
  border: 1px solid #fff;
  border-radius: 100%;
  background-color: rgba(42, 57, 109, 0.8);
}
.radio input[type="radio"]:checked + .radio-label:before {
  background-color: #4cd0d0;
  box-shadow: inset 0 0 0 3.5px #fff;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 700;
}
.radio input[type="radio"]:checked + .radio-label {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 700;
}
.radio input[type="radio"]:checked + .radio-label-mobile {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 700;
}

.radio input[type="radio"]:checked + .radio-label-mobile:before {
  background-color: #4cd0d0;
  box-shadow: inset 0 0 0 3px #fff;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}


@media (max-width: 768px) {
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    padding-left: 30px;
    font-size: 13px!important;
  }
  .form-control {
    font-size: 12px !important;
  height: 30px;
  }
}

.svg-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 1em;
  background-image: url(/tempo/img/flecheD.png);
  background-position-x: 94%;
}
.select-opt {
  border: 2px solid #fff;
  border-radius: 2rem;
  color: #fff;
  font-size: 0.8vw;
  background-color: rgba(42, 57, 109, 0.8);
}

.position-block {
  margin-top: -160px;
}

.position-text {
  margin-top: calc(15 * (1vw + 1vh - 1vmin)) !important;
}
.position-text-mobile {
  margin-top: calc(9 * (1vw + 1vh - 1vmin)) !important;
}
.position-block-mobile {
  margin-top: calc(-10 * (1vw + 1vh - 1vmin));
}

.md-block {
  display: none;
}

@media (max-width: 768px) {
  .md-block {
    display: block !important;
  }

  .btn {
    line-height: 0;
  }
}


.md-block-imgs {
  display: none;
}

@media (max-width: 886px) {
  .md-block-imgs {
    display: block !important;
  }

  .display-max-imgs {
    display: none;
  }

  .btn {
    line-height: 0;
  }
}

.ourHr2 {
  background-color: #cdcdcd;
  height: 3px;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
}

.svg-arrow2 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 0.5em;
  background-image: url(/tempo/img/flechedown.png);
  background-position-x: 94%;
  text-align: center;
  -moz-text-align-last: center;
  text-align-last: center;
}

.btn-rond {
  height: 35px;
  float: right;
}

.b-visible {
  visibility: hidden;
}

.btn-rond-mobile {
  height: calc(4 * (1vw + 1vh - 1vmin));
}
.img-annonce {
  height: 465px!important;
  width: 395px!important;
}
@media (max-width: 1440px) {
  /* .img-annonce {
    height: 385px;
  width: 315px;
  } 
  .mt-7 {
    margin-top: 5rem !important;
  }*/
}
/* @media (max-width: 1250px) {
  .img-annonce {
    height: 295px;
  width: 225px;
  }
}
@media (max-width: 1024px) {
  .img-annonce {
    height: 245px;
  width: 175px;
  }
}
@media (max-width: 870px) {
  .img-annonce {
    height: 185px;
  width: 125px;
  }
} */
.img-test {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  filter: Alpha(opacity=80);
  -moz-opacity: 0.8;
  opacity: 0.9;
}
.block-test {
  background-color: rgba(12, 15, 64, 0.9);
  background-size: auto;
  z-index: 1;
}
.block-test-mobile {
  background-color: rgba(12, 15, 64, 0.9);
  background-size: auto;
  z-index: 1;
}
.block-test1 {
  background-color: #fff;
  background-size: auto;
  z-index: 0;
}
.block-test1-mobile {
  background-color: #fff;
  background-size: auto;
  z-index: 0;
}
#corps {
  position: relative;
}
#corps:before {
  content: "";
  background: url("/img/home.png") fixed 0 0;
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -ms-filter: blur(10px);
  -o-filter: blur(10px);
  filter: blur(10px);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.img-annonce-mobile {
  height: calc(15 * (1vw + 1vh - 1vmin));
}

.annonces:hover {
  transform: scale(1.1);
  -webkit-box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.slick-slide:hover {
  z-index: 100;
}

.annonces:hover .b-visible {
  visibility: visible;
}
.slick-prev,
.slick-next{  
  border: none;
  background-color: transparent;
  margin-left: 15px;
  margin-right: 15px;
}

#carouselannones-mobile .carousel-control-next-icon {
  background-image: url(/castelnaudary/img/next1.png);
}

#carouselannones-mobile .carousel-control-prev-icon {
  background-image: url(/castelnaudary/img/back.png);
}
#carouselannones-mobile .carousel-control-next {
  right: 4vh;
  width: 30%;
}
#carouselannones-mobile .carousel-control-prev {
  left: 4vh;
  width: 30%;
}

.imgcarousel-mobile {
  height: 375px;
}

.carousel-indicators {
  bottom: -4vh;
}

.carousel-indicators .active {
  background-color: #4cd0d0;
}

.button-vert2-mobile {
  font-size: 13px;
  background-color: #4cd0d0;
  height: 7.5vmin;
  border-radius: 50px;
  border: 2px solid #4cd0d0;
  background-color: #f8f8f8;
  color: #4cd0d0;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.tig-size-left {
  height: calc(40 * (1vw + 1vh - 1vmin));
  top: calc(-6 * (1vw + 1vh - 1vmin));
  position: absolute;
  left: calc(-18 * (1vw + 1vh - 1vmin));
}

.tig-size-right {
  height: 800px;
}

.tig-img-container {
  overflow: hidden;
}

.tig-size-mobile {
  height: calc(45 * (1vw + 1vh - 1vmin));
}
.estimation {
  font-size: 64px;
  font-family: "PlutoMedium";
  color: #3e549f;
  line-height: 0;
}
.gratuite {
  font-size: 97px;
  font-family: "PlutoBold";
  color: #2a396d;
}
.text-block {
  font-size: 18px;
  font-family: "PlutoCondRegular";
  color: #6c6c6c;
}
.savoir-plus {
  font-size: 16px;
  font-family: "PlutoCondMedium";
  color: #58595b;
  text-transform: uppercase;
  font-weight: 600;
}
@media (max-width: 1440px) {
  /*.estimation {
    font-size: 44px;
  }
   .gratuite {
    font-size: 77px;
  } */
  /* .text-block {
    font-size: 16px;
  } */
  .savoir-plus {
    font-size: 16px;
  }
}
@media (max-width: 1024px) {
  /*.estimation {
    font-size: 34px;
  }
   .gratuite {
    font-size: 60px;
  } */
  /* .text-block {
    font-size: 14px;
  } */
  .savoir-plus {
    font-size: 14px;
  }
}

.estimation-mobile {
  font-size: 24px;
  font-family: "PlutoMedium";
  color: #3e549f;
  line-height: 0;
}
.gratuite-mobile {
  font-size: 24px;
  font-family: "PlutoBold";
  color: #2a396d;
}

.savoir-plus-mobile {
  font-size: 14px;
  font-family: "PlutoCondMedium";
  color: #6c6c6c;
  text-transform: uppercase;
  font-weight: 600;
}
.gestion {
  font-size: 74px;
  font-family: "PlutoMedium";
  color: #cf3127;
  line-height: 0;
}
.locative {
  font-size: 110px;
  font-family: "PlutoCondMedium";
  color: #741711;
  font-weight: 900;
}

.chiffre-locative {
  font-size: 36px;
  font-family: "PlutoCondMedium";
  color: #cf3127;
}
.text-locative {
  font-size: 17px;
  font-family: "PlutoCondMedium";
  color: #1a1a1d;
}

@media (max-width: 1440px) {
  /* .gestion {
    font-size: 54px;
  }
  .locative {
    font-size: 90px;
  } */
  /* .chiffre-locative {
    font-size: 26px;
  } */
  /* .text-locative {
    font-size: 20px;
  } */
}
@media (max-width: 1024px) {
  /* .gestion {
    font-size: 44px;
  }
  .locative {
    font-size: 70px;
  } */
  /* .chiffre-locative {
    font-size: 20px;
  } */
  /* .text-locative {
    font-size: 15px;
  } */
}

.gestion-mobile {
  font-size: 24px;
  font-family: "PlutoMedium";
  color: #cf3127;
  line-height: 0;
}
.locative-mobile {
  font-size: 24px;
  font-family: "PlutoBold";
  color: #741711;
  font-weight: 900;
}

.text-block-mobile {
  font-size: 14px;
  font-family: "PlutoCondMedium";
  color: #58595b;
}

.btn-rond:hover {
  transform: scale(1.1);
}

.img-resp2 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.img-resp3 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: initial;
}

.rounded-img-container {
  width: 27vmax;
  height: 27vmax;
  border-radius: 50%;
  overflow: hidden;
}

.rounded-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

#estimation-gratuite.h-size-img {
  height: calc(30 * (1vw + 1vh - 1vmin));
  margin-top: calc(11 * (1vw + 1vh - 1vmin)) !important;
}

#estimation-gratuite.h-size-left {
  height: calc(30 * (1vw + 1vh - 1vmin));
}

#estimation-gratuite .position-img {
  position: absolute;
  top: calc(-7 * (1vw + 1vh - 1vmin));
  left: calc(-8 * (1vw + 1vh - 1vmin));
}

#estimation-gratuite .left-col-img-container{
  width: 1110px;
}

#estimation-gratuite .position-img-4 {
  position: absolute;
  left: -210px;
}
@media (max-width:1830px){
  #estimation-gratuite .position-img-4 {
    left: -230px;
  }
}
@media (max-width:1520px){
  #estimation-gratuite .position-img-4 {
    left: -260px;
  }
}

.picto-final{
  height: 16px;
}

#estimation-gratuite .position-img-5 {
  position: absolute;
  right: -289px;
}
@media (max-width:1628px){
  #estimation-gratuite .position-img-5 {
    right: -452px;
  }
}

  .display-min-cercles {
    display: none;
  }

@media (max-width:1210px){
  .display-max-cercles {
    display: none;
  }
  .display-min-cercles {
    display: block !important;
  }
}

#estimation-gratuite .position-img-2 {
  position: absolute;
  top: calc(-10 * (1vw + 1vh - 1vmin));
  right: calc(-5 * (1vw + 1vh - 1vmin));
}

#estimation-gratuite .position-img-3 {
  position: absolute;
  top: 224px;
  right: -342px;
}
@media (max-width : 2020px){
  #estimation-gratuite .position-img-3 {
    right: -520px;
  }
}
@media (max-width : 1650px){
  #estimation-gratuite .position-img-3 {
    right: -715px;
  }
}

#estimation-gratuite .o-img {
  width: 566px;
  height: 566px;
  clip-path: url(#maskRect1);
}

#estimation-gratuite .o-img-mobile {
  width: 330px;
  height: 330px;
  clip-path: url(#maskRect3);
}
@media (max-width: 430px) {
#estimation-gratuite .o-img-mobile {    
  width: 250px;
  height: 250px;
}
}
#estimation-gratuite .o-img-2 {
  width: 700px;
  height: 700px;
  clip-path: url(#maskRect2);
}

#estimation-gratuite .circle-left {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
  width: calc(18 * (1vw + 1vh - 1vmin));
  height: calc(18 * (1vw + 1vh - 1vmin));
  position: absolute;
  border-radius: 50%;
  left: 8vmax;
  top: -3vmax;
}

#estimation-gratuite .circle-right {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
  width: calc(24 * (1vw + 1vh - 1vmin));
  height: calc(24 * (1vw + 1vh - 1vmin));
  position: absolute;
  right: calc(-5 * (1vw + 1vh - 1vmin));
  border-radius: 50%;
  top: calc(-6 * (1vw + 1vh - 1vmin));
}

#estimation-gratuite .filled-circle {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

#estimation-gratuite .filled-circle-mobile {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
  width: calc(2 * (1vw + 1vh - 1vmin));
  height: calc(2 * (1vw + 1vh - 1vmin));
  border-radius: 50%;
}

#estimation-gratuite .holled-circle {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
  width: 160px;
  height: 160px;
  border-radius: 50%;
}

#estimation-gratuite .holled-circle-mobile {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
  width: calc(5 * (1vw + 1vh - 1vmin));
  height: calc(5 * (1vw + 1vh - 1vmin));
  border-radius: 50%;
}

#estimation-gratuite .gray-circle {
  width: 31%;
  background-color: #eff0f4;
  height: 31%;
  border-radius: 50%;
}

.taille-col-agence{
  width: 460px;
}

#estimation-gratuite .container-circles {
  position: absolute;
  height: 556px;
  right: 0;
  top: 0;
}

#estimation-gratuite .container-circles-5 {
  position: absolute;
  height: 619px;
  left: 0;
  top: 0;
}

.margin-left-ag {
  margin-left: 500px !important;
}

.margin-left-ag-lft {
  margin-left: 237px !important;
}

#estimation-gratuite .container-circles-mobile {
  position: absolute;
  width: calc(15 * (1vw + 1vh - 1vmin));
  right: calc(4 * (1vw + 1vh - 1vmin));
  bottom: calc(7 * (1vw + 1vh - 1vmin));
}

#estimation-gratuite .container-circles-2 {
  position: absolute;
  height: 670px;
  left: 0;
  top: calc(2 * (1vw + 1vh - 1vmin));
}

#estimation-gratuite .filled-circle-2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  float: right;
}

#estimation-gratuite .position-img-mobile {
  position: absolute;
  height: calc(32 * (1vw + 1vh - 1vmin));
}

#estimation-gratuite .image-right-container{
  height: 400px;
}

.img-rond {
  background-repeat: no-repeat;
  background-position: inherit;
  background-size: cover;
}

.estimation span.monBR,
.gestion span.monBR {
  display: block;
  line-height: 1.3;
}
.mt-5m {
  margin-top: 4.5rem !important;
}

.text-size-sel {
  font-size: 16px;
}

.contact {
  font-size: 40px;
  font-family: "PlutoBold";
  color: #2a396d;
  text-transform: uppercase;
  line-height: 1.2;
}
.contact-mobile {
  font-size: 24px;
  font-family: "PlutoBold";
  color: #2a396d;
  text-transform: uppercase;
}
.text-contact {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #282828;
}
.text-contact-mobile {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #282828;
}

.text-obligatoire {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #9fa3a7;
}
.maps {
  height: calc(25 * (1vw + 1vh - 1vmin));
}

.slick-no-slide .slick-track {
  width: 100% !important;
  text-align: center;
  transform: inherit !important;
}
.slick-no-slide.slick-slide {
  float: none;
  display: inline-block;
}

.maps-mobile {
  height: calc(40 * (1vw + 1vh - 1vmin));
}

.mon-margin {
  margin-top: 0rem !important;
}

@media (max-width: 1024px) {
  /* .texts-sites {
    font-size: 2vmin;
  }
  .texts-sites2 {
    font-size: 2vmin;
  } */
  .mon-margin {
    margin-top: 0.2rem !important;
  }
}

.bck-header-size {
  background-size: auto, contain !important;
}
.bck-header-size2 {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.error-titre {
  font-size: calc(6 * (1vw + 1vh - 1vmin));
}

.error-text {
  font-size: calc(2 * (1vw + 1vh - 1vmin));
}
.mt5 {
  margin-top: 3rem !important;
}
@media (max-width: 1024px) {
  .mt5 {
    margin-top: 0.5rem !important;
  }
}

.container-1870 {
  max-width: 1870px;
}
.container-1415 {
  max-width: 1415px;
}
.container-1682 {
  max-width: 1682px;
}
.mt-10 {
  margin-top: 10rem!important;
}
.container-1420 {
  max-width: 1420px;
}
.container-1168 {
  max-width: 1168px;
}


/* widget rating */

@media (max-width:1088px){
  .os-bootstrap .global-satisfaction-percentage{
    font-size: 29px !important;
  }

  .os-bootstrap .star-64{
    width: 100% !important;
    background-position: left center !important;
    background-size: 166px !important;
  }

  .os-bootstrap .star-64 span{
    background-position: left center !important;
    background-size: 166px !important;
  }

  .os-bootstrap .survey-box .smiley{
    left: inherit !important;
  }

  .os-bootstrap .survey-box .star-16{
    width: 100% !important;
    background-size: 40px !important;
  }

  .os-bootstrap .survey-box .star-16 span {
    background-size: 40px !important;
  }
}

/* end widget rating */
#menu-module.menu-position {
  position: absolute;
  z-index: 10;
}
@media (max-width: 768px) {
  #menu-module.menu-position {
    position: initial;
  }
}

#menu-module .menu-container {
  padding-top: 18px;
  padding-bottom: 0px;
  padding-left: 55px;
  padding-right: 50px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
@media (max-width: 1350px) {
  #menu-module .menu-container {
    padding-top: 8px;
    padding-left: 40px;
    padding-right: 37px;
  }
}
@media (max-width: 1190px) {
  #menu-module .menu-container {
    padding-top: 6px;
    padding-left: 36px;
    padding-right: 32px;
  }
}
@media (max-width: 950px) {
  #menu-module .menu-container {
    padding-top: 2px;
    padding-left: 29px;
    padding-right: 27px;
  }
}

#menu-module .loop-container {
  margin: 0px;
  margin-left: 2px;
}

#menu-module .fav-container {
  margin: 0px;
  margin-left: 15px;
}

#menu-module .loop {
  width: 19px;
  /* height: 17px; */
}
#menu-module .fav {
  width: 22px;
  /* height: 21px; */
  cursor: pointer;
}
@media (max-width: 1350px) {
  #menu-module .loop {
    width: 17px;
  }
  #menu-module .fav {
    width: 20px;}
}
@media (max-width: 1190px) {
  #menu-module .loop {
    width: 15px;
  }
  #menu-module .fav {
    width: 18px;}
}
@media (max-width: 950px) {
  #menu-module .loop {
    width: 13px;
  }
  #menu-module .fav {
    width: 16px;}
}

#menu-module .search-bar {
  width: 290px;
  height: 21px;
  border: none;
  background-color: transparent;
  border-bottom: solid 1px #ffffff;
  outline: none;
  color: #ffffff;
}


@media (max-width: 1350px) {
  #menu-module .search-bar {
    width: 210px;
    height: 21px;
  }
}
@media (max-width: 1190px) {
  #menu-module .search-bar {
    width: 165px;
    height: 19px;
  }
}
@media (max-width: 950px) {
  #menu-module .search-bar {
    width: 135px;
    height: 17px;
  }
}

#menu-module .nav-container {
  margin: 0px;
  margin-top: 29px;
}

#menu-module .logo {
  width: 170px;
  height: auto;
}

@media (max-width: 1740px) {
  #menu-module .logo {
    width: 140px;
  }
}
@media (max-width: 1350px) {
  #menu-module .logo {
    width: 120px;
  }
}
@media (max-width: 1190px) {
  #menu-module .logo {
    width: 100px;
  }
}
@media (max-width: 950px) {
  #menu-module .logo {
    width: 75px;
  }
}

#menu-module .nav-button-item {
  font-size: 15px;
  /* padding-top: 12px;
  padding-bottom: 9px;
  margin-top: 20px;
  margin-bottom: 7px; */
  padding-right: 20px;
  padding-left: 20px;
  margin-left: 8px;
  margin-right: 0px;
  font-family: "MontserratRegular";
  color: #ffffff;
  border-radius: 20px;
  background-color: #4cd0d0cc;
}
@media (max-width: 1740px) {
  #menu-module .nav-button-item {
    font-size: 13px;
    padding-right: 9px;
    padding-left: 9px;
  }
}
@media (max-width: 1350px) {
  #menu-module .nav-button-item {
    font-size: 11px;
    padding-right: 7px;
    padding-left: 7px;
  }
}
@media (max-width: 1190px) {
  #menu-module .nav-button-item {
    font-size: 9px;
    padding-right: 5px;
    padding-left: 5px;
  }
}
@media (max-width: 950px) {
  #menu-module .nav-button-item {
    font-size: 7px;
  }
}

#menu-module .nav-item {
  font-size: 15px;
  /* padding-top: 12px;
  padding-bottom: 9px;
  margin-top: 20px;
  margin-bottom: 7px; */
  padding-right: 20px;
  padding-left: 20px;
  margin-left: 11px;
  margin-right: 0px;
  font-family: "MontserratRegular";
  color: #ffffff;
}

#menu-module .nav-item.active {
  font-weight: bold;
  text-decoration: underline;
}

@media (max-width: 1740px) {
  #menu-module .nav-item {
    font-size: 13px;
    padding-right: 13px;
    padding-left: 13px;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 1350px) {
  #menu-module .nav-item {
    font-size: 11px;
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media (max-width: 1190px) {
  #menu-module .nav-item {
    font-size: 9px;
    padding-right: 8px;
    padding-left: 8px;
  }
}
@media (max-width: 950px) {
  #menu-module .nav-item {
    font-size: 7px;
  }
}

#menu-module .menu-mobile-container{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  padding-top: calc(19*100vw/375);
  padding-right: calc(26*100vw/375);
  padding-left: calc(18*100vw/375);
  padding-bottom: 0;
}

#menu-module .burger-container{
  margin: 0;
  margin-top: calc(12*100vw/375);
  margin-bottom: calc(15*100vw/375);
}

#menu-module .logo-mobile{
  width: 130px;
}

#menu-module .util-mobile-container{
  padding: 0;
  padding-top: calc(12*100vw/375);
  padding-bottom: calc(15*100vw/375);
}

#menu-module .menu-mobile-container{
  margin: 0;
  margin-bottom: calc(15*100vw/375);
}

#menu-module .logo-mobile-container{
  margin: 0;
  margin-right: calc(32*100vw/375);
  margin-left: calc(74*100vw/375);
}

#menu-module .loop-mobile-container{
  margin: 0;
  margin-left: calc(29*100vw/375);
}

#menu-module .loop-mobile{
  width: 17px;
}

#menu-module .fav-mobile{
  width: 19px;
}

#menu-module .burger{
  width: 18px;
}

#menu-module .text-menu-mobile{
  font-family: "MontserratRegular";
  color: #FFFFFF;
  font-size: 15px;
}

#menu-module .text-buttons-menu-mobile{
  font-family: "MontserratRegular";
  color: #FFFFFF;
  font-size: 15px;
  border-radius: 2rem;
  background-color: #4cd0d0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
}

#menu-module .menu-mobile-master{
  overflow: auto;
  z-index: 100;
  position: fixed;
}

#menu-module .container-fav {
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: calc(2 * (1vw + 1vh - 1vmin));
  max-width: 1870px;
  width: 100%;
  z-index: 200;
}
@media  (max-width: 1870px) {
  #menu-module .container-fav {
    margin: auto;
    top: calc(2 * (1vw + 1vh - 1vmin));
    right: calc(1.7 * (1vw + 1vh - 1vmin));
    position: absolute;
    z-index: 200;
  }
}


#menu-module .badge {
  background-color: #4cd0d0;
  border-radius: 10px;
  color: white;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  padding: 3px 7px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}

#menu-module .shopping-cart {
  margin: 20px 0;
  float: right;
  background: white;
  width: 390px;
  position: relative;
  border-radius: 3px;
  padding: 20px;
}
@media (max-width: 768px) {
  #menu-module .shopping-cart {
    width: 311px;
  }
}
#menu-module .shopping-cart .shopping-cart-header {
  border-bottom: 1px solid #E8E8E8;
  padding-bottom: 15px;
}
#menu-module .shopping-cart .shopping-cart-header .shopping-cart-total {
  float: right;
}
#menu-module .shopping-cart .shopping-cart-items {
  padding-top: 20px;
  list-style-type: none;
}
#menu-module .shopping-cart .shopping-cart-items li {
  margin-bottom: 18px;
}
#menu-module .shopping-cart .shopping-cart-items div {
  float: left;
  margin-right: 12px;
  width: 159px;
  height: 159px;
}
@media (max-width: 768px) {
  #menu-module .shopping-cart .shopping-cart-items div {
    float: left;
    margin-right: 12px;
    width: calc(5 * (1vw + 1vh - 1vmin));
    height: calc(5 * (1vw + 1vh - 1vmin));
  }
}

#menu-module .shopping-cart .shopping-cart-items .item-name {
  display: block;
  padding-top: 10px;
  color: #000000
}
#menu-module .shopping-cart .shopping-cart-items .item-price {
  margin-right: 8px;
}
#menu-module .shopping-cart .shopping-cart-items .item-quantity {
  color: #ABB0BE;
}

/* #menu-module .shopping-cart:after {
  bottom: 100%;
  left: 96%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: white;
  border-width: 8px;
  margin-left: -8px;
} */

#menu-module .cart-icon {
  color: #515783;
  font-size: 24px;
  margin-right: 7px;
  float: left;
}

@media (max-width: 768px) {
  #menu-module .container-fav {
    margin: auto;
    top: 20vmin;
    right: calc(0.7 * (1vw + 1vh - 1vmin));
  }

  /* #menu-module .shopping-cart:after {
    left: 82%;
  } */
}


.mt-10 {
  margin-top: 10rem !important;
}

.img-resp1 {
  background-repeat: no-repeat;
  background-position: inherit;
  background-size: contain;
}

.position-block-text {
  margin-top: calc(-3.5 * (1vw + 1vh - 1vmin)) !important;
}
.ourHr-menu {
  background-color: #1b2752;
  height: 2px;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  margin-left: auto;
  margin-right: auto;
}

#footer-module .burguer-menu-footer {
    display: none;
  }
  
  #footer-module .list-footer-mobile li {
    font-size: 15px;
    font-family: "PlutoRegular";
    color: #d7d7d8;
    word-spacing: 0.1vw;
    margin-top: 0.5vh;
    line-height: 2;
  }
  
  #footer-module .list-footer-mobile a {
    text-decoration: none;
    font-family: "PlutoRegular";
    color: #d7d7d8;
  }
  
  #footer-module .back-footer-mobile {
    background-color: #2a396d;
  }
  
  #footer-module .list-footer a {
    text-decoration: none;
    font-family: "PlutoRegular";
    color: #d7d7d8;
  }
  
  #footer-module .list-footer li {
    font-size: 15px;
    font-family: "PlutoRegular";
    color: #d7d7d8;
    word-spacing:1px;
    margin-top: 0.5vh;
  }
  
  #footer-module .list-footer a:hover {
    font-family: "PlutoBold";
    font-size: 16px;
  }
  
  #footer-module .back-footer {
    background-color: #2c3648;
  }
  
  #footer-module .back-footer2 {
    background-color: #2a396d;
    height: 12vh;
  }
  
  #footer-module .icon-footer {
    width: 25px;
  }
  
  #footer-module .burger-menu-footer {
    width: 18px;
  }
  
  #footer-module .text-menu{
    font-size: 14px;
    font-family: "PlutoCondMedium";
    color:#fff;
    text-transform: uppercase; 
  }

  #footer-module .text-menu-mobile {
    font-family: "PlutoCondMedium";
    color: #fff;
    font-size: 14px;
    letter-spacing: 0vw;
}

  .end_content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.center_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#lagence  .icons-size-mobile {
    height: 80px;
}


#lagence .home-header-size{
    height: 75vh;
}

#lagence  .icons-size {
    height: 80px;
}

#lagence  .cercle2-size-mobile {
    height: calc(50 * (1vw + 1vh - 1vmin));
}

#lagence  .cercle2-size {
    height: calc(45 * (1vw + 1vh - 1vmin));
}
#lagence .text-icons {
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #464646;
}
/* 
@media (max-width: 1440px) {
    #lagence .text-icons {
        font-size: 13px!important;
  }
} */

#lagence .text-icons-mobile {
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #464646;
}

#lagence .img-icon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

#lagence .img-rond {
    background-repeat: no-repeat;
    background-position: initial;
    background-size: contain;
}

#lagence .position-block1 {
    margin-top: -26vh;
}

#lagence .position-block1-mobile {
    position: relative;
    top: -35vw;
}
#lagence .position-etapes {
    margin-top: -4vh;
}
#lagence  .rougec-size {
    height: 280px;
}

#lagence  .rougec-size-mobile {
    height:280px;
}

#lagence  .equipe-size {
    height: 260px;
    width: 212px;
}
#lagence  .equipe-size:hover {
    -webkit-box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.4);
    transform: scale(1.1);
}
#lagence  .equipe-size-mobile {
    height: 260px;
    width: 225px;
}
#lagence .conseil{
    font-size: 24px;
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    color: #911a12;
    letter-spacing: 1px;
}
#lagence .rigueur{
    font-size: 24px;
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    color: #bc3227;
    letter-spacing: 1px;
}
#lagence .ecoute{
    font-size: 24px;
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    color: #63211c;
    letter-spacing: 1px;
}
#lagence .conseil-mobile{
    font-size: 24px;
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    color: #911a12;
    letter-spacing: 1px;
}
#lagence .rigueur-mobile{
    font-size: 24px;
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    color: #bc3227;
    letter-spacing: 1px;
}
#lagence .ecoute-mobile{
    font-size: 24px;
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    color: #63211c;
    letter-spacing: 1px;
}

#lagence .text-prix {
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    color: #464646;
    font-weight: 500;
    letter-spacing: 1px;
  }
  
#lagence .text-prix-mobile {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    color: #464646;
    font-weight: 500;
    letter-spacing: 1px;
  }

#lagence .text-vendeur {
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #464646;
    letter-spacing: 1px;
}
#lagence .text-vendeur-mobile {
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #464646;
    letter-spacing: 1px;
}


#lagence .background-logo {
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
  }

#lagence .carousel-indicators .active {
    background-color: #3e549f;
}

#lagence .carousel-indicators {
    bottom: -3vh;
}

.sous-titresE {
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #2a396d;
}
.sous-titresE-mobile {
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #2a396d;
}

#lagence .text-poste {
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #464646;
}
#lagence .text-poste-mobile {
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #464646;
}
#lagence .taille-col{
    width: 286px;
}
#lagence .taille-col-agence{
    width: 460px;
}
#lagence .taille-col-etapes{
    width: 360px;
}
#lagence .taille-col-equipe{
    width: 220px;
}

#lesservices  .signerond-size {
    height: 170px;
    width: 170px;
    border-radius: 50%;
}
#lesservices  .signerond-size-mobile {
    height: 170px;
    width: 170px;
    border-radius: 50%;
}

#lesservices  .cercle2-size-mobile {
  height: calc(55 * (1vw + 1vh - 1vmin));
}

#lesservices  .cercle2-size {
  height: calc(40 * (1vw + 1vh - 1vmin));
}

#lesservices .titres4 {
    font-size: 2.5vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #464646;
    word-spacing: 0.3vw;
  }
#lesservices .titres4-mobile {
    font-size: 5vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #464646;
    word-spacing: 0.3vw;
  }

  #lesservices .carousel-indicators .active {
    background-color: #3e549f;
}

#lesservices .text-icons {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #464646;
}
#lesservices .text-icons-mobile {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #464646;
}

#lesservices .home-header-size {
  height: calc(24 * (1vw + 1vh - 1vmin));
}

#lesservices .img-resp-cercle {
  background-repeat: no-repeat;
  background-position: inherit;
  background-size: contain;
}
#lesservices .taille-col-block{
  width: 460px;
}
#lesservices .taille-col-travail{
  width: 370px;
}

#lesservices .height-header{
  height: 75vh;
}



#estimation-gratuite  .signerond-size {
    height: calc(8 * (1vw + 1vh - 1vmin));
}

#gestionlocative .home-header-size {
  height: 75vh;
}

#estimation-gratuite  .signerond-size-mobile {
    height: calc(16 * (1vw + 1vh - 1vmin));
}

#estimation-gratuite  .cercle2-size-mobile {
  height: calc(45 * (1vw + 1vh - 1vmin));
}

#estimation-gratuite  .cercle2-size {
  height: calc(55 * (1vw + 1vh - 1vmin));
}

#estimation-gratuite  .cercle3-size {
  height: calc(44 * (1vw + 1vh - 1vmin));
}
#estimation-gratuite .titres4 {
    font-size: 2.5vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #464646;
    word-spacing: 0.3vw;
  }
#estimation-gratuite .titres4-mobile {
    font-size: 5vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #464646;
    word-spacing: 0.3vw;
  }

  #estimation-gratuite .carousel-indicators .active {
    background-color: #3e549f;
}

#estimation-gratuite .text-icons {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #464646;
}
#estimation-gratuite .text-icons-mobile {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #464646;
}
@media (max-width: 1024px){
  #estimation-gratuite .text-icons {
    font-size: 12px;
  }
}
#liste-biens .bien-header-size {
    height: 75vh;
  }
#liste-biens .bien-header-size-mobile {
    height: calc(75 * (1vw + 1vh - 1vmin));
}
#liste-biens .resultat-size {
  height: 265px;
  width: 460px;
  }
#liste-biens .resultat-size-mobile {
    height: 227px;
}
#liste-biens .h-35 {
    height: 35% !important;
}

#liste-biens .margin-bt-head {
  margin-bottom: 191px !important;
}

#liste-biens .ourHr1 {
    background-color: #fff;
    height: 1px;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
}
#liste-biens .ourHr3 {
    background-color: #dfdfdf;
    height: 1px;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
}
#liste-biens .texts-sites4 {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    font-weight: 500;
}
#liste-biens .texts-sites4-mobile {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    font-weight: 500;
}
@media (max-width: 768px) {
#liste-biens .texts-sites4-mobile {
  font-size: 12px;
}
}

#liste-biens .radio-number input[type="radio"]:checked + .radio-label::before {
    background-color: #4cd0d0;
    box-shadow: inset 0 0 0 2px #fff;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    font-weight: 700;
}
#liste-biens .radio-number [type="radio"]:checked + label::before, #liste-biens .radio-number [type="radio"]:not(:checked) + label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 15px;
    height: 15px;
    border: 1px solid #fff;
    border-radius: 100%;
    background: #fff;
    background-color: rgba(42, 57, 109, 0.8);
    top: -33px;
    left: 22px;
}
/* @media (max-width: 768px) {
#liste-biens [type="radio"]:checked + label,
#liste-biens [type="radio"]:not(:checked) + label {
    padding-left: 8vw;
    font-size: 14px;
  }
#liste-biens   .form-control {
    font-size: 14px !important;
  }
} */


#liste-biens [type="radio"]:checked,
#liste-biens [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
#liste-biens [type="radio"]:checked + label,
#liste-biens [type="radio"]:not(:checked) + label {
  padding-left: 23px;
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
}
@media (max-width: 840px) {
#liste-biens [type="radio"]:checked + label,
#liste-biens [type="radio"]:not(:checked) + label {
  font-size: 11px;
}
#liste-biens .texts-sites4 {
  font-size: 13px;
}
}

#liste-biens .radio input[type="radio"]:checked + .radio-label:before {
  background-color: #4cd0d0;
  box-shadow: inset 0 0 0 3.5px #fff;
  color: #fff;
  font-weight: 700;
}
#liste-biens .radio input[type="radio"]:checked + .radio-label {
  color: #fff;
  font-weight: 700;
}
#liste-biens .radio input[type="radio"]:checked + .radio-label-mobile {
  color: #fff;
  font-weight: 700;
}

#liste-biens  .radio
  input[type="radio"]:checked
  + .radio-label-mobile:before {
  background-color: #4cd0d0;
  box-shadow: inset 0 0 0 3px #fff;

}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}


@media (max-width: 768px) {
  /* [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    padding-left: 8vw;
    font-size: 3vw;
  } */

  #liste-biens .radio-number 
  [type="radio"]:checked + label::before, 
  #liste-biens .radio-number 
  [type="radio"]:not(:checked) + label::before {
    left: 6.6vw;
  }

  #liste-biens [type="radio"]:checked + label, #liste-biens [type="radio"]:not(:checked) + label {
    padding-left: 6.7vw;
  }

  [type="radio"]:checked + label::before, [type="radio"]:not(:checked) + label::before {
    top:5px;
  }

  #liste-biens .page-link {
    padding: 2.5vw!important;
    font-size: 2.8vw!important;
  }

  #liste-biens .page-item.active .page-link {
    border:none!important;
  }

  /* #liste-biens .svg-arrow2 {
    font-size: 2.5vw;
  } */
}

#liste-biens .position-pieces {
    top: -3.5vh;
}

#liste-biens .filtrer {
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #464646;
    text-transform: uppercase;
    word-spacing:1px;
  }
#liste-biens   .filtrer-mobile {
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #464646;
    text-transform: uppercase;
    word-spacing:1px;
  }

#liste-biens .titre-resultat {
    font-size: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #2a396d;
}
#liste-biens .text-resultat {
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #464646;
}
#liste-biens .text-resultat-mobile {
    font-size:16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #464646;
}
#liste-biens .text-prix {
    font-size: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #464646;
}
#liste-biens .text-prix-mobile {
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #464646;
}

#liste-biens .titres2g {
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  color: #2a396d;
  word-spacing:1px;
}
#liste-biens .titres2g-mobile {
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  color: #2a396d;
  word-spacing:1px;
}

#liste-biens .btn-rond {
  height: 45px;
  float: right;
}

#liste-biens .btn-rond:hover {
  transform: scale(1.1);
}

#liste-biens .page-link {
  border: none;
  font-size: 21px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #464646;
  padding: 21px;
}

#liste-biens .btn-fleche {
  height: 40px;
}
#liste-biens .btn-fleche-mobile {
  height: 40px;
}

#liste-biens .margin-flecheR{
  margin-right: 20vw;
}

#liste-biens .margin-flecheL{
  margin-left: 20vw;
}

#liste-biens .margin-flecheR-mobile{
  margin-right: 22vw;
}

#liste-biens .margin-flecheL-mobile{
  margin-left: 22vw;
}
#liste-biens .page-item.active .page-link {
  z-index: 1;
  background-color: #2a396d;
  color:#fff;
  height: calc(2.5 * (1vw + 1vh - 1vmin));
  border-radius: 50%;
  border: 5px solid #949cb6;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  width: 46px;
}

#liste-biens .link-num:hover,
#liste-biens .page-item .margin-flecheR:hover,
#liste-biens .page-item .margin-flecheL:hover,
#liste-biens .page-item .margin-flecheR-mobile:hover,
#liste-biens .page-item .margin-flecheL-mobile:hover{
  background-color:transparent;
  transform: scale(1.2);
}
#liste-biens .page-item.active .page-link:hover {
  transform: scale(1);
}

#liste-biens .svg-arrow2 {
  text-align: left;
  text-align-last: left;
  text-transform: uppercase;
}


#liste-biens .form-control2 {
  color: #464646;
  font-weight: 600;
}

#liste-biens .btn-rond-mobile {
  height: calc(4 * (1vw + 1vh - 1vmin));
  float: right;
}


#bien .bien-size {
  height: calc(20 * (1vw + 1vh - 1vmax));
}
#bien .bien-size-mobile {
  height: calc(40 * (1vw + 1vh - 1vmin));
}
#bien .resultat-size {
  height: 386px;
}
#bien .resultat-size-mobile {
  height: 226px;
}
#bien .img-print {
  height: 23px;
}
#bien .img-print-mobile {
  height: 25px;
}
#bien .imgeco-size {
  height: 280px;
}
#bien .imgeco-size-mobile {
  height: 216px;
}

#bien #bien-mobile .text-buttons-mobile{
  font-size: 13px;
}

#bien .container-1682 {
  max-width: 1682px;
}
@media (max-width: 1682px) {
  #bien .container-1682 {
    max-width: 100%;
  }
}

#bien .text-resultat {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #232642;
}
#bien .text-resultat-mobile {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #232642;
}
#bien .text-prix1 {
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #232642;
}
#bien .text-prix1-mobile {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #232642;
}
#bien .text-ref {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #232642;
}
#bien .text-ref-mobile {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #232642;
}
#bien .titres2g {
  font-size: 1.5vw;
  font-family: "PlutoBold";
  color: #2a396d;
  word-spacing: 0.3vw;
}
#bien .titres2g-mobile {
  font-size: 3.5vw;
  font-family: "PlutoBold";
  color: #2a396d;
  word-spacing: 0.3vw;
}

#bien .img-resp2 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

#bien .zoom {
  height: calc(1 * (1vw + 1vh - 1vmin));
  float: right;
}

#carouselbien-mobile .carousel-indicators,
#carouselbien .carousel-indicators {
  bottom: 0;
  margin-bottom: 2.5vh;
}

@media (max-width: 768px) {
  #carouselbien-mobile .carousel-indicators li {
    width: 1vmax;
    height: 1vmax;
    margin-bottom: 0.5vh;
  }
}

@media (max-width: 1600px) {
  #carouselbien .carousel-indicators {
    bottom: 0;
    margin-bottom: 1vh;
  }
}

#bien .showcontact {
  transition-property: transform;
  transition-duration: 1s;
  transition-timing-function: ease; /* Transition de type ease */
}

#bien .showcontact:hover {
  transform: scale(1.1);
  transform: translateY(30px);
  color: #fff;
}

#bien .show-contact {
  display: none;
}
#bien .show-contact-mobile {
  display: none;
}
.img-bien:active {
  transform: scale(1.8);
  transition: 1s;
}

#bien .print-page {
  cursor: pointer;
}

#bien .img-contact {
  height: 60px;
}

#bien .img-contact-mobile {
  height: 60px;
}

#bien .lb-nav a.lb-next {
  background: url(/tempo/lightbox/next.png) right 48% no-repeat;
}

#bien .lb-nav a.lb-prev {
  background: url(/tempo/lightbox/prev.png) left 48% no-repeat;
}

#bien .lb-data .lb-close {
  background: url(/tempo/lightbox/close.png) top right no-repeat;
}
#bien .lb-cancel {
  background: url(/tempo/lightbox/loading.gif) no-repeat;
}

#proposervente .nf-form-content .listselect-wrap .nf-field-element div,
.nf-form-content .ninja-forms-field {
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #9fa3a7;
}

.nf-form-content .list-select-wrap .nf-field-element > div,
.nf-form-content input:not([type="button"]),
.nf-form-content textarea {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #464646;
}
.ninja-forms-form-wrap *, .ninja-forms-form-wrap :after, .ninja-forms-form-wrap :before {
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #9fa3a7;
}

#proposervente .home-header-size{
  height: 75vh;
}

#proposervente .position-expertise{
 margin-top: -120px!important;
}

#programmes-neufs .bien-header-size {
    height: calc(35 * (1vw + 1vh - 1vmin));
  }
#programmes-neufs .bien-header-size-mobile {
    height: calc(75 * (1vw + 1vh - 1vmin));
}
#programmes-neufs .resultat-size {
    height: calc(17 * (1vw + 1vh - 1vmin));
  }
#programmes-neufs .resultat-size-mobile {
    height: calc(27 * (1vw + 1vh - 1vmin));
}
#programmes-neufs .h-35 {
    height: 35% !important;
}
#programmes-neufs .ourHr1 {
    background-color: #fff;
    height: 1px;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
}
#programmes-neufs .ourHr3 {
    background-color: #dfdfdf;
    height: 1px;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
}
#programmes-neufs .texts-sites4 {
    font-size: .9vw;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    font-weight: 600;
}
#programmes-neufs .texts-sites4-mobile {
    font-size: 2.9vw;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    font-weight: 600;
}

#programmes-neufs .radio-number input[type="radio"]:checked + .radio-label::before {
    background-color: #4cd0d0;
    box-shadow: inset 0 0 0 2px #fff;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    font-weight: 700;
}
#programmes-neufs .radio-number [type="radio"]:checked + label::before, #programmes-neufs .radio-number [type="radio"]:not(:checked) + label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 15px;
    height: 15px;
    border: 1px solid #fff;
    border-radius: 100%;
    background: #fff;
    background-color: rgba(42, 57, 109, 0.8);
    top: -3.5vh;
    left: 1.8vw;
}
/* @media (max-width: 768px) {
#programmes-neufs [type="radio"]:checked + label,
#programmes-neufs [type="radio"]:not(:checked) + label {
    padding-left: 8vw;
    font-size: 3vw;
  }
#programmes-neufs   .form-control {
    font-size: 3vw !important;
  }
  
} */


#programmes-neufs [type="radio"]:checked,
#programmes-neufs [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
#programmes-neufs [type="radio"]:checked + label,
#programmes-neufs [type="radio"]:not(:checked) + label {
  padding-left: 2vw;
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-weight: 600;
}

#programmes-neufs .radio input[type="radio"]:checked + .radio-label:before {
  background-color: #4cd0d0;
  box-shadow: inset 0 0 0 3.5px #fff;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-weight: 700;
}
#programmes-neufs .radio input[type="radio"]:checked + .radio-label {
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-weight: 700;
  font-size: 1vw;
}
#programmes-neufs .radio input[type="radio"]:checked + .radio-label-mobile {
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
}

#programmes-neufs  .radio
  input[type="radio"]:checked
  + .radio-label-mobile:before {
  background-color: #4cd0d0;
  box-shadow: inset 0 0 0 3px #fff;

}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}


@media (max-width: 768px) {
  /* [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    padding-left: 8vw;
    font-size: 3vw;
  } */

  #programmes-neufs .radio-number 
  [type="radio"]:checked + label::before, 
  #programmes-neufs .radio-number 
  [type="radio"]:not(:checked) + label::before {
    left: 6.6vw;
  }

  #programmes-neufs [type="radio"]:checked + label, #programmes-neufs [type="radio"]:not(:checked) + label {
    padding-left: 6.7vw;
  }

  [type="radio"]:checked + label::before, [type="radio"]:not(:checked) + label::before {
    top:5px;
  }

  #programmes-neufs .page-link {
    padding: 2.5vw!important;
    font-size: 2.8vw!important;
  }

  #programmes-neufs .page-item.active .page-link {
    border:none!important;
  }

  #programmes-neufs .svg-arrow2 {
    font-size: 2.5vw;
  }
}

#programmes-neufs .position-pieces {
    top: -3.5vh;
}

#programmes-neufs .filtrer {
    font-size: 1.5vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #464646;
    text-transform: uppercase;
    word-spacing: 0.3vw;
  }
#programmes-neufs   .filtrer-mobile {
    font-size: 3.5vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #464646;
    text-transform: uppercase;
    word-spacing: 0.3vw;
  }

#programmes-neufs .text-resultat {
    font-size: 1vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #464646;
}
#programmes-neufs .text-resultat-mobile {
    font-size: 2.9vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #464646;
}
#programmes-neufs .text-prix {
    font-size: 1.5vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    color: #464646;
}
#programmes-neufs .text-prix-mobile {
    font-size: 4vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    color: #464646;
}

#programmes-neufs .titres2g {
  font-size: 1.5vw;
  font-family: "PlutoBold";
  color: #2a396d;
  word-spacing: 0.3vw;
}
#programmes-neufs .titres2g-mobile {
  font-size: 3.5vw;
  font-family: "PlutoBold";
  color: #2a396d;
  word-spacing: 0.3vw;
}

#programmes-neufs .btn-rond {
  height: calc(2.3 * (1vw + 1vh - 1vmin));
  float: right;
}

#programmes-neufs .btn-rond:hover {
  transform: scale(1.1);
}

#programmes-neufs .page-link {
  border: none;
  font-size: 1.1vw;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #464646;
  padding: 1vw;
}

#programmes-neufs .btn-fleche {
  height: calc(1.4 * (1vw + 1vh - 1vmin));
}
#programmes-neufs .btn-fleche-mobile {
  height: calc(3 * (1vw + 1vh - 1vmin));
}

#programmes-neufs .margin-flecheR{
  margin-right: 20vw;
}

#programmes-neufs .margin-flecheL{
  margin-left: 20vw;
}

#programmes-neufs .margin-flecheR-mobile{
  margin-right: 22vw;
}

#programmes-neufs .margin-flecheL-mobile{
  margin-left: 22vw;
}
#programmes-neufs .page-item.active .page-link {
  z-index: 1;
  background-color: #2a396d;
  color:#fff;
  height: calc(2.5 * (1vw + 1vh - 1vmin));
  border-radius: 50%;
  border: 5px solid #949cb6;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2vw;
  width: 2vw;
}

#programmes-neufs .link-num:hover,
#programmes-neufs .page-item .margin-flecheR:hover,
#programmes-neufs .page-item .margin-flecheL:hover,
#programmes-neufs .page-item .margin-flecheR-mobile:hover,
#programmes-neufs .page-item .margin-flecheL-mobile:hover{
  background-color:transparent;
  transform: scale(1.2);
}
#programmes-neufs .page-item.active .page-link:hover {
  transform: scale(1);
}

#programmes-neufs .svg-arrow2 {
  text-align: left;
  text-align-last: left;
  text-transform: uppercase;
}

.form-control2 {
  color: #464646;
  font-weight: 600;
}

#programmes-neufs .btn-rond-mobile {
  height: calc(4 * (1vw + 1vh - 1vmin));
  float: right;
}

#programmes-neufs  .cercle2-size-mobile {
    height: calc(55 * (1vw + 1vh - 1vmin));
}

#programmes-neufs  .cercle2-size {
    height: calc(45 * (1vw + 1vh - 1vmin));
}

#programmes-neufs .text-resultat {
    font-size: 1vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #464646;
}
#programmes-neufs .text-resultat-mobile {
    font-size: 2.8vw;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #464646;
}

#programmes-neufs .img-resp {
    background-repeat: no-repeat;
    background-position: inherit;
    background-size: contain;
}

#programmes-neufs .position-block {
  margin-top: calc(-4 * (1vw + 1vh - 1vmin)) !important;
}

#programmes-neufs .position-text {
  margin-top: calc(17 * (1vw + 1vh - 1vmin)) !important;
}

#programmes-neufs .img-resp1 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#contact .bien-size {
    height: calc(20 * (1vw + 1vh - 1vmax));
}

.nf-form-content .nf-field-container #nf-field-10-wrap .nf-field-element .ninja-forms-field {
    background-color: rgba(76,208,208,.9);
    height: 32px;
    border-radius: 50px;
    padding: 0;
    padding-left: 23px;
    padding-right: 23px;
}

.nf-response-msg p{
    font-size: 15px;
}
#pagination-module .page-link {
    border: none;
    font-size: 21px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    color: #464646;
    padding: 21px;
  }
  
  #pagination-module .btn-fleche {
    height: 40px;
  }
  #pagination-module .btn-fleche-mobile {
    height: 40px;
  }
  
  #pagination-module .margin-flecheR{
    margin-right: 20vw;
  }
  
  #pagination-module .margin-flecheL{
    margin-left: 20vw;
  }
  
  #pagination-module .margin-flecheR-mobile{
    margin-right: 22vw;
  }
  
  #pagination-module .margin-flecheL-mobile{
    margin-left: 22vw;
  }
  #pagination-module .page-item.active .page-link {
    z-index: 1;
    background-color: #2a396d;
    color:#fff;
    height: calc(2.5 * (1vw + 1vh - 1vmin));
    border-radius: 50%;
    border: 5px solid #949cb6;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    width: 46px;
  }
  
  #pagination-module .link-num:hover,
  #pagination-module .page-item .margin-flecheR:hover,
  #pagination-module .page-item .margin-flecheL:hover,
  #pagination-module .page-item .margin-flecheR-mobile:hover,
  #pagination-module .page-item .margin-flecheL-mobile:hover{
    background-color:transparent;
    transform: scale(1.2);
  }
  #pagination-module .page-item.active .page-link:hover {
    transform: scale(1);
  }